<template>
<div v-for="item in tuiguang" :key="item" >

     
  <div class="main" style="margin:auto;" v-for="list in item.banner" :key="list">
      <img :src="list.banner1" />
      <img :src="list.banner2" />
      <img :src="list.banner3" />
      <img :src="list.banner4" />
      <img :src="list.banner5" />
      <img :src="list.banner6" />
      <img :src="list.banner7" />
      <img :src="list.banner8" />
      <img :src="list.banner9" />
      <img :src="list.banner10" />
      <img :src="list.banner11" />
      <img :src="list.banner12" />
      <img :src="list.banner13" />
      <img :src="list.banner14" />
      <img :src="list.banner15" />
      <img :src="list.banner16" />
      <img :src="list.banner17" />
      <img :src="list.banner18" />
      <img :src="list.banner19" />
      <img :src="list.banner20" />
      <img :src="list.banner21" />
      <img :src="list.banner22" />
      <img :src="list.banner23" />
      <img :src="list.banner24" />
      <img :src="list.banner25" />
      <img :src="list.banner26" />
      <img :src="list.banner27" />
      <img :src="list.banner28" />
      <img :src="list.banner29" />
      <img :src="list.banner30" />
      <img :src="list.banner31" />
      <img :src="list.banner32" />
      <img :src="list.banner33" />
      <img :src="list.banner34" />
      <img :src="list.banner35" />
  </div>
  <div class="foot-div"  v-for="list in item.main" :key="list">
      <div class="main">
        <div class="wx-ico"><img :src="list.main1" /></div>
        <div class="text">{{ list.main2 }}<span id="aa">{{ list.main3}}</span></div>
        <a class="foot-btn" onclick="copyArt()">{{ list.main4}}</a>
      </div>
  </div>
   


</div> 
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      tuiguang: null,
    }
  },
  mounted() {
    this.add();
  },
  methods: {
    async add() {
      axios.get('https://api.bf839.com/ad6.json').then((response) => {
        if (response) {
          this.tuiguang = response.data.data.tuiguang;
          console.log('数据获取成功');
        }
        else (
          alert('数据获取失败')
        )
      }
      )
    }
  },

}
</script>

 